var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "video-overview"
  }, [_c('div', {
    staticClass: "main-content"
  }, [_c('Row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('div', [_c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('Col', {
    staticStyle: {
      "height": "inherit!important",
      "margin-bottom": "12px"
    }
  }, [_c('Card', [_c('CheckUrl')], 1)], 1), _c('Col', {
    staticStyle: {
      "height": "inherit!important",
      "margin-bottom": "12px"
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "type": "ONE_VALUE",
      "force-endpoint": "api/vastconfigs/percentVast"
    }
  })], 1), _c('Col', {
    staticStyle: {
      "height": "inherit!important",
      "margin-bottom": "12px"
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "force-endpoint": "/api/vastconfigs/risk",
      "type": "RISK"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('Col', [_c('ApiWidgetFactory', {
    staticStyle: {
      "height": "calc(100% - 24px)"
    },
    attrs: {
      "type": "CHART",
      "force-endpoint": "/api/vastconfigs/vastPie"
    }
  })], 1)], 1)], 1)], 1)])], 1), _c('Card', [_c('h3', [_vm._v(_vm._s(_vm.$t('Latest high-risk inventory', {
    amount: 5
  })))]), _c('FilterTable', {
    attrs: {
      "fetch-items-params": {
        riskLevel: 0.6,
        creative_type: 'Programmatic'
      },
      "default-paging": {
        sort: 'Vastconfigs.modified',
        direction: 'desc',
        limit: 5
      },
      "limit": 5,
      "border": true,
      "stripe": true,
      "columns": _vm.columns,
      "fetch-items": _vm.fetch
    },
    on: {
      "on-items-fetched": _vm.callback
    }
  })], 1), _c('Card', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('Latest high-risk creatives in payloads', {
    amount: 5
  })))]), _c('FilterTable', {
    attrs: {
      "fetch-items-params": {
        riskLevel: 0.6,
        origin: 'Payload'
      },
      "default-paging": {
        sort: 'Vastconfigs.modified',
        direction: 'desc',
        limit: 5
      },
      "limit": 5,
      "border": true,
      "stripe": true,
      "columns": _vm.payloadColumns,
      "fetch-items": _vm.fetch
    },
    on: {
      "on-items-fetched": _vm.callback
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }