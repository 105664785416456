<template>
  <main class="video-overview">
    <div class="main-content">
      <Row :gutter="24">
        <Col span="24">
          <div>
            <Row type="flex"
                 :gutter="24"
            >
              <Col span="12"
                   style="margin-bottom:12px"
              > 
                <Col style="height:inherit!important; margin-bottom:12px">
                  <Card>
                    <CheckUrl />
                  </Card>
                </Col>
                <Col style="height:inherit!important; margin-bottom:12px">
                  <ApiWidgetFactory
                    type="ONE_VALUE"
                    force-endpoint="api/vastconfigs/percentVast"
                  />
                </Col>
                <Col style="height:inherit!important; margin-bottom:12px">
                  <ApiWidgetFactory force-endpoint="/api/vastconfigs/risk"
                                    type="RISK"
                  />
                </Col>
              </Col>

              <Col span="12">
                <Col>
                  <ApiWidgetFactory
                    style="height: calc(100% - 24px);"
                    type="CHART"
                    force-endpoint="/api/vastconfigs/vastPie"
                  />
                </Col>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Card>
        <h3>{{ $t('Latest high-risk inventory', { amount: 5 }) }}</h3>
        <!--:search-filters="['search', 'riskLevel']" -->
        <FilterTable
          :fetch-items-params="{riskLevel: 0.6, creative_type: 'Programmatic'}"
          :default-paging="{sort:'Vastconfigs.modified', direction:'desc', limit: 5}"
          :limit="5"
          :border="true"
          :stripe="true"
          :columns="columns"
          :fetch-items="fetch"
          @on-items-fetched="callback"
        />
      </Card>
      <Card style="margin-top:24px">
        <h3>{{ $t('Latest high-risk creatives in payloads', { amount: 5 }) }}</h3>
        <FilterTable
          :fetch-items-params="{riskLevel: 0.6, origin: 'Payload'}"
          :default-paging="{sort:'Vastconfigs.modified', direction:'desc', limit: 5}"
          :limit="5"
          :border="true"
          :stripe="true"
          :columns="payloadColumns"
          :fetch-items="fetch"
          @on-items-fetched="callback"
        />
      </Card>
    </div>
  </main>
</template>

<script>
// import { mapState } from 'vuex'
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
import columnsMixin from '@/views/Vast/campaign/columnsMixin/hColumns'
import payloadColumnsMixin from '@/views/Vast/campaign/columnsMixin/payloadColumns'
import CheckUrl from './CheckUrl'
import ApiWidgetFactory from '@/components/ui/ApiWidgetFactory'
import FilterTable from '@/components/ui/FilterTable'

export default {
  name: 'VideoOverview',
  mixins: [columnsMixin, payloadColumnsMixin],
  components: {CheckUrl, ApiWidgetFactory, FilterTable},
  data () {
    return {
      data: null,
      loading: true
    }
  },
  methods: {

    ...mapActions({
      getVastConfigs: 'video/getVastConfigs'
    }),
    callback(res) {
      this.data = res.data
    },
    fetch ({params, uid = this._uid}) {
      return this.getVastConfigs(params)
        .then(r => r)
    },

  }
}
</script>

<style lang="scss" scoped>
  .video-overview {

    .widget-grid {
      width: 100%;
      padding-bottom: 20px;

      .ivu-col {
        padding: 20px 0;
      }

      .chart-legend {
        text-align: center;
        padding: 10px 0;
      }

      .big-data {
        letter-spacing: 3px;
        color: #8a9190;
        font-size: 34px;
        line-height: 180px;
        text-align: center;
        display: block;
        height: 197px;
      }
    }

  }
</style>
