var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isLoading ? _c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('global.loading')))])], 1) : _c('div', [_c('Form', {
    ref: "formCustom",
    staticClass: "check-form",
    attrs: {
      "model": _vm.formValidate,
      "rules": _vm.ruleValidate,
      "label-position": "top",
      "inline": ""
    }
  }, [_c('br'), _c('FormItem', {
    attrs: {
      "prop": "vastUrl"
    }
  }, [_c('Row', [_c('Col', {
    attrs: {
      "lg": 18
    }
  }, [_c('Input', {
    staticClass: "check-input",
    attrs: {
      "placeholder": "https://domain.ext/vast-tag.xml",
      "type": "text"
    },
    model: {
      value: _vm.formValidate.vastUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "vastUrl", $$v);
      },
      expression: "formValidate.vastUrl"
    }
  })], 1), _c('Col', {
    attrs: {
      "lg": {
        span: 4,
        offset: 1
      }
    }
  }, [_c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleSubmit('formCustom');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.t('inspect VAST ')) + " ")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }