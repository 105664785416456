<template lang="html">
  <div>
    <Spin
      fix
      v-if="isLoading"
    >
      <Icon
        type="ios-loading"
        size="18"
        class="demo-spin-icon-load"
      />
      <div>{{ $t('global.loading') }}</div>
    </Spin>
    <div
      v-else
    >
      <!--      <h3>{{ t('VAST tester') }}</h3>-->
      <Form
        ref="formCustom"
        class="check-form"
        :model="formValidate"
        :rules="ruleValidate"
        label-position="top"
        inline
      >
        <br>
        <FormItem
          prop="vastUrl"
        >
          <Row>
            <Col :lg="18">
              <Input
                class="check-input"
                placeholder="https://domain.ext/vast-tag.xml"
                type="text"
                v-model="formValidate.vastUrl"
              />
            </Col>
            <Col :lg="{span:4, offset:1}">
              <Button
                type="primary"
                @click="handleSubmit('formCustom')"
              >
                {{ t('inspect VAST ') }}
              </Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import translate from '@/mixins/translate'

export default {
  name: 'CheckUrl',
  mixins: [translate],

  data () {
    return {
      isLoading: false,
      formValidate: {
        vastUrl: '',
        date: ''
      },
      ruleValidate: {
        vastUrl: [
          {
            type: 'url',
            required: true,
            message: 'Incorrect Url',
            trigger: 'blur'
          }
        ],
        date: [{}]
      }
    }
  },
  methods: {
    ...mapActions({
      testIt: 'video/testVAST'
    }),
    /**
     * Send the VAST data to the api
     * the API return the vast id
     * redirect to the /:id
     */
    handleSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { formValidate } = this
          const url = formValidate.vastUrl
          // return this.testIt({url: url}).then(r => {
            
          // })
          this.isLoading = true
          this.$router.push({
            path: '/vast/vast_config/draft',
            query: { url }
          })
          
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/*
    .check-vast {
      .main-container {
        border-radius: 0.25rem;
        -webkit-box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
        background: #fff;
        padding-top: 30px;

        .check-form .ivu-form-item {
          padding: 10px 0;
        }

        h3 {
          font-weight: 500;
          margin-bottom: 20px;
        }

        .ivu-form {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          .agency {
            display: inline-block;
            margin-right: 20px;

            .check-input {
              width: 100%;
            }
          }

          .getTypes {
            display: inline-block;
          }

          .ivu-form-item-error {
            margin-bottom: 50px;
          }

          .check-input {
            .ivu-input {
              height: 50px !important;
              box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12) !important;
            }
          }
        }
      }
    }

      .check-vast {
      .ivu-select-dropdown-list {
        max-height: 250px !important; //it deals w/ the list width& height
        left: inherit !important;
        top: inherit !important;
        overflow-y: scroll !important;
      }

      .check-input {
        .ivu-input {
          height: 50px !important;
          box-shadow: 0 2px 3px 0 rgba(60, 66, 87, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12) !important;
          border-radius: 8px !important;
        }
      }
    }*/
</style>
